<template>
    <div>
  <base-template @headerBack="headerBack">
    <LoginTop class="login-top" />
    <div class="login-passwoord-info">
      <div class="title">{{ $t('login.inputPassWord') }}</div>
      <div :class="[showPass?'show-pass':'hide-pass']" class="password-opr">
        <van-field
          v-model="dataForm.password"
          center
          class="password-input"
          :class="a>=375 ? 'password-input' :'password-inputplaceholder'"
          maxlength="12"
          type="digit"
          :placeholder="$t('login.inputPassWordP')"
          @blur="blurFun('pwd_duration')" 
          @focus="focusFun('pwd_num')"
        />
        <!-- <span class="eye" @click="showPass=!showPass" /> -->
      </div>

      <p class="login-for-otp"><span @click="goOptLogin">{{ $t('login.SMSLogin') }}</span></p>
      <privacidad ref="privacidad" class="privacidad agree" @changeHandle="changeHandle"
      
      />
      <submit-btn ref="submit-btn" :sub-text="$t('login.loginText')" :submit-flag="submitFlag" @submit="userLogin" />
      <p class="forget-password"><span @click="forgetPassword">{{ $t('login.forgetPassword') }}</span></p>
    </div>
  </base-template>
  
</div>
</template>

<script>
import LoginTop from '@/views/login/components/LoginTop'
import Privacidad from '@/views/register/components/Privacidad'
import toRouter from "@/mixins/toRouter";
import { userLogin,loginPoint } from '@/api/axios/index'
import { setToken, setUser } from '@/utils/auth'
import { getnewtime, getinterval } from '@/utils'
export default {
  mixins: [toRouter],
  name:'PassLogin',
  components: { LoginTop, Privacidad },
  data() {
    return {
      isAgree: true,
      showPass: false,
      dataForm: {
        mobile: this.$route.query.mobile,
        login_status: '1',
        password: ''
      },
      a:window.screen.width
    }
  },
  created () {
  },
  computed: {
    submitFlag() {
      return this.dataForm.password.length > 5 && this.isAgree
    }
  },
  watch:{
    'dataForm.password':{
      handler(){
        this.updatecountfun('password','pwd_updatecount')
      }
    }
  },
  methods: {


    // 获取焦点
    focusFun(nametwo) {
      this.start = getnewtime(); // 初始化时间
      this.$store.state.settings.point[nametwo]++
    },

    // 失去焦点 - 计算时长
    blurFun(name) {
      //  计算时长
      this.$store.state.settings.point[name] += getinterval(
        getnewtime(),
        this.start
      );
      console.log(getinterval(
        getnewtime(),
        this.start
      ));
    
    },
    // 次数
    updatecountfun(name,nametwo) {
      // 初始化不要走
      if (!this.dataForm[name]) {
        this.$store.state.settings.point[nametwo]++;
      }
    },





    async userLogin() {
      this.$store.state.settings.point.login_num++
      console.log(this.$store.state.settings.point,'this.$store.state.settings.point.login_num');
      if (this.dataForm.password.length < 6) return

      try {
        const params = {
          mobile_phone_number: this.$route.query.mobile,
          Umusango_Ibumba: '1',
          Infungulo_yankama: this.dataForm.password
        }
        const { data } = await userLogin(params) || {}
        console.log(data)
        setToken(data?.token)
        setUser(data)
        sessionStorage.setItem('userInfo', JSON.stringify(data || {}))
        // await loginPoint(this.$store.state.settings.point)
        this.$H5Object.loginSuccess(data)
        
      } catch (err) {
        // console.log(err)
      }
    },

    // 忘记密码
    forgetPassword() {
      
      this.$router.push({
        path: '/register',
        query: {
          forgetPass: 'forgetPass', // 忘记密码标识
          mobile: this.dataForm.mobile
        }
      })
    },

    // otp登录
    goOptLogin() {
      this.$router.push({
        path: '/otpLogin',
        query: {
          mobile: this.dataForm.mobile
        }
      })
    },

    headerBack() {
      this.$router.push({
        path: '/login',
        query: {
          mobile: this.dataForm.mobile
        }
      })
    },

    // 隐私协议状态
    changeHandle(val) {
      console.log(val,'hahhaha');
      if(val){
        this.$store.state.settings.point[read_num]++
      }
      this.isAgree = val
    }
  }
}
</script>
<style lang="scss" scoped>
:deep(.is-content){
  padding-left: 64px;
  padding-right: 64px;
}
.main-content{
  background: #fff;
}
:deep(.child-title){
  display: none;
}
.login-top{
  margin-top: 42px;
}
.login-passwoord-info{
  padding-top: 86px;
  .title{
    font-size: 32px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: #1A1A1A;
    line-height: 48px;
    margin-bottom: 32px;
  }
}

:deep(.btn-box){
  padding-top: 0;
  margin-bottom: 0;
}

:deep(.phone-input),
:deep(.password-input){
  width: 100%;
  height: 128px;
  padding: 0;
  margin-bottom: 32px;
  background: #F7F7F7FF;
  border-radius: 32px;
  border: none;
  text-indent: 48px;
    
}
.password-opr{
  /deep/ .password-inputplaceholder{
    input::-webkit-input-placeholder{
      width: 570px;
      white-space: inherit ;
      position: relative;
      top: -10px;
      // transform:translateY(-10px)
    }
}
} 
:deep(.password-input){
  margin-bottom: 26px;
}
:deep(.btn-box .submit-btn){
  width: auto;
  margin: 0;
}

:deep(.phone-input .van-field__label){
  position: relative;
  font-size: 28px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #0B0320;
  padding: 34px;
  width: auto;
}
:deep(.phone-input .van-field__label::after){
  content: '';
  position: absolute;
  height: 56px;
  border: 2px solid #E7E7E7;
  top: 50%;
  margin-top: -28px;
  right: 0;
}

:deep(.phone-input .van-field__control),
:deep(.password-input .van-field__control){
  font-size: 28px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #0B0320;
  padding-left: 26px;
  letter-spacing: 3px;
}
:deep(.phone-input .van-field__control:disabled){
  -webkit-text-fill-color:#323233;
  color: #323233;
}

.password-opr{
  position: relative;
  .eye{
    position: absolute;
    width: 40px;
    height: 40px;
    top: 30px;
    right: 34px;
  }
}

.hide-pass{
  .eye{
    background: url('~@/assets/images/login/hide-pass.png') no-repeat center center;
    background-size: 100%;
  }
  :deep(.van-field__control){
    -webkit-text-security: disc;
  }
}
.show-pass{
  .eye{
    background: url('~@/assets/images/login/show-pass.png') no-repeat center center;
    background-size: 100%;
  }
}

.forget-password{
  font-size: 32px;
  font-family: Roboto-Medium, Roboto;
  font-weight: 500;
  color: #1C1D21;
  line-height: 40px;
  text-align: center;
  margin-top: 48px;
}
.login-for-otp{
  font-size: 26px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #005FE9;
  line-height: 30px;
  margin-bottom: 88px;
  text-align: right;
}
:deep(.login-bg){
  margin-bottom:84px;
}

.agree{
  font-size: 26px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #999999;
  line-height: 40px;
  margin-bottom: 24px;
  display: flex;
}

</style>
<style>
.password-opr input::-webkit-input-placeholder{
  font-size: 24px;
  letter-spacing: 0px;
}
</style>
